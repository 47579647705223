import useStyles from 'isomorphic-style-loader/useStyles';
import { useTranslation } from 'react-i18next';

import ownStyles from './styles.scss';

export const Atom_Logo_Text: React.FC<Props> = (props) => {
  useStyles(ownStyles);

  const { styles, ...divAttrs } = props;
  const [translate] = useTranslation('atoms/Logo/Text');
  const allStyles = React.extendStyles(ownStyles, styles);

  return (
    <h1 classNames={allStyles.text} {...divAttrs}>
      <div classNames={allStyles.title}>Claire Duchamp</div>
      <div classNames={allStyles.subtitle}>{translate('architect')}</div>
    </h1>
  );
};

export const LogoText = Atom_Logo_Text;
